export const EventType = {
  // Homepage
  HOMEPAGE_VIEW: 'View Homepage',
  HOMEPAGE_CLICK_SIGNUP: 'Homepage: Click Signup',

  // Header Events
  HEADER_CLICK_SIGNUP: 'Header: Click Signup',
  HEADER_CLICK_LOGIN: 'Header: Click Login',
  HEADER_CLICK_UPGRADE: 'Header: Click Upgrade',
  HEADER_CLICK_MENU: 'Header: Click Menu Item',

  // Search
  SEARCH_QUERY: 'Search: Query',
  SEARCH_CLICK_RESULT: 'Search: Click Result',
  SEARCH_CLICK_RECENT: 'Search: Click Recent Search',

  // Account Page
  ACCOUNT_CLICK_RENEW: 'Account: Renew Subscription',
  ACCOUNT_FOLLOW_USER: 'Account: Follow User',
  ACCOUNT_UNFOLLOW_USER: 'Account: Unfollow User',
  ACCOUNT_FOLLOW_TAG: 'Account: Follow Tag',
  ACCOUNT_UNFOLLOW_TAG: 'Account: Unfollow Tag',

  // Banner
  BANNER_CLICK_RENEW: 'Banner: Click Renew Banner',
  BANNER_CONFIRM_RENEW: 'Banner: Confirm Renew Subscription',

  // Landing Page Events
  LANDING_VIEW: 'View Landing',
  LANDING_CLICK_SIGNUP: 'Landing: Click Signup',
  LANDING_CLICK_VIEW_COURSES: 'Landing: Click View Courses',
  LANDING_CLICK_TRACK: 'Landing: Click Track',

  // Login/Signup Page
  SIGNUP_VIEW: 'View Signup',
  LOGIN_VIEW: 'View Login',
  LOGIN_CLICK_SSO: 'Login: Click SSO Login',
  LOGIN_SUCCESS: 'Login: Success',

  // Course List Page
  COURSE_LIST_VIEW: 'View Courses',
  COURSE_LIST_CLICK_COURSE: 'Courses: Click Course',
  COURSE_LIST_SET_CAREER_GOALS: 'Courses: Set Career Goals',
  COURSE_LIST_CLICK_BUY: 'Courses: Click Buy Course',
  COURSE_LIST_SEARCH: 'Courses: Search',
  COURSE_LIST_CLICK_CHICLET: 'Courses: Click Chiclet',
  COURSE_LIST_CLICK_LESSON: 'Courses: Click Lesson',

  // Course Page
  COURSE_VIEW: 'View Course',
  COURSE_VIEW_SIGNUP_WALL: 'Course: View Signup Wall',
  COURSE_VIEW_UPGRADE_WALL: 'Course: View Upgrade Wall',
  COURSE_CLICK_SIGNUP_WALL: 'Course: Click Signup Wall',
  COURSE_CLICK_UPGRADE_WALL: 'Course: Click Upgrade Wall',
  COURSE_CLICK_MASTERCLASS: 'Course: Click Masterclass',
  COURSE_CLICK_LESSON: 'Course: Click Lesson',
  COURSE_CLICK_FREE_LESSON: 'Course: Click Free Lesson',
  COURSE_CLICK_UPGRADE: 'Course: Click Upgrade',
  COURSE_CLICK_CONTINUE: 'Course: Click Continue',
  COURSE_CLICK_SHOW_MORE_TESTIMONIALS: 'Course: Click Show More Testimonials',
  COURSE_CLICK_RELATED_COURSES: 'Course: Click Related Course',
  COURSE_CLICK_SHOW_MORE_COURSES: 'Course: Click Show More Courses',
  COURSE_CLICK_VIEW_CERTIFICATE: 'Course: Click View Certificate',
  COURSE_CLICK_ADD_TO_LINKEDIN: 'Course: Click Add to LinkedIn',

  // Course Upsell
  COURSE_UPSELL_VIEW: 'Course Upsell: View',
  COURSE_UPSELL_CLICK_PLAN: 'Course Upsell: Click Plan',
  COURSE_UPSELL_CLICK_BUY: 'Course Upsell: Click Buy',
  COURSE_UPSELL_CLICK_MORE: 'Course Upsell: Click More',

  // Lesson Page
  LESSON_VIEW: 'View Lesson',
  LESSON_VIEW_VIDEO: 'Lesson: View Video',
  LESSON_PLAY_VIDEO: 'Lesson: Play Video',
  LESSON_CLICK_HINT: 'Lesson: Click Hint',
  LESSON_VIEW_SOLUTION: 'Lesson: View Solution',
  LESSON_VIEW_VIDEO_SOLUTION: 'Lesson: View Video Solution',
  LESSON_VIEW_COMMUNITY_ANSWERS: 'Lesson: View Community Answers',
  LESSON_CLICK_VOTE: 'Lesson: Click Vote',
  LESSON_CLICK_SUBMIT_FEEDBACK: 'Lesson: Click Submit Feedback',
  LESSON_CLICK_NEXT: 'Lesson: Click Next',
  LESSON_VIEW_SIGNUP_WALL: 'Lesson: View Signup Wall',
  LESSON_CLICK_SIGNUP_WALL: 'Lesson: Click Signup Wall',
  LESSON_VIEW_PAYWALL: 'Lesson: View Paywall',
  LESSON_CLICK_UPGRADE: 'Lesson: Click Upgrade',
  LESSON_CLICK_FREE_LESSON: 'Lesson: Click Free Lesson',
  LESSON_CLICK_SIDEBAR: 'Lesson: Click Sidebar',
  LESSON_CLICK_SIDEBAR_UPGRADE: 'Lesson: Click Sidebar Upgrade',
  LESSON_CLICK_START_RUBRIC: 'Lesson: Click Start Rubric',
  LESSON_SUBMIT_RUBRIC: 'Lesson: Submit Rubric',
  LESSON_START_COMMENT: 'Lesson: Start Comment',
  LESSON_SUBMIT_COMMENT: 'Lesson: Submit Comment',
  LESSON_CLICK_SEE_MORE_ANSWERS: 'Lesson: Click See More User Answers',
  LESSON_CLICK_SAVE: 'Lesson: Click Save',
  LESSON_CLICK_LINK: 'Lesson: Click Link',
  LESSON_VIEW_EXPERT_ANSWER: 'Lesson: View Expert Answer',
  LESSON_CLICK_EXPERT_ANSWER_UPGRADE: 'Lesson: Click Expert Answer Upgrade',

  // Resource Page
  RESOURCES_VIEW: 'View Resources',
  RESOURCE_VIEW: 'Resource: View',
  RESOURCE_VIEW_SIGNUP_WALL: 'Resource: View Signup Wall',
  RESOURCE_CLICK_SIGNUP_WALL: 'Resource: Click Signup Wall',
  RESOURCE_VIEW_PAYWALL: 'Resource: View Paywall',
  RESOURCE_CLICK_UPGRADE: 'Resource: Click Upgrade',

  // Code Editor
  CODE_EDITOR_VIEW: 'Code Editor: View',
  CODE_EDITOR_EDIT_CODE: 'Code Editor: Edit Code',
  CODE_EDITOR_RUN_CODE: 'Code Editor: Run Code',
  CODE_EDITOR_RUN_TESTS: 'Code Editor: Run Tests',
  CODE_EDITOR_PASSED_TESTS: 'Code Editor: Passed Tests',

  // Record Answer Modal
  RECORD_ANSWER_CLICK_RECORD_ANSWER: 'Record Answer: Click Record Answer',
  RECORD_ANSWER_CLICK_TABS: 'Record Answer: Click Tabs',
  RECORD_ANSWER_CLICK_START_RECORDING: 'Record Answer: Click Start Recording',
  RECORD_ANSWER_CLICK_REDO: 'Record Answer: Click Redo',
  RECORD_ANSWER_CLICK_DONE: 'Record Answer: Click Done',
  RECORD_ANSWER_CLICK_SELF_ASSESSMENT: 'Record Answer: Click Self Assessment',
  RECORD_ANSWER_CLICK_PRACTICE_AGAIN: 'Record Answer: Click Practice Again',
  RECORD_ANSWER_CLICK_ASK_COACHING: 'Record Answer: Click Ask Exponent Coach',
  CODE_EDITOR_CLICK_SHARE: 'Code Editor: Click Share',

  // Upgrade Page
  UPGRADE_VIEW: 'View Upgrade Page',
  UPGRADE_CLICK_PLAN_TYPE: 'Upgrade: Click Plan Type',
  UPGRADE_CLICK_BUY: 'Upgrade: Click Buy',
  UPGRADE_CANCEL: 'Upgrade: Cancel Checkout',
  UPGRADE_SUCCESS_VIEW: 'View Upgrade Success',
  PAYMENT_SUCCESS: 'Payment Success',

  // Career Pages
  CAREER_VIEW: 'View Career Page',

  // Company Pages
  COMPANY_VIEW: 'Company: View Company Guide',
  COMPANY_CLICK_COURSE: 'Company: Course Upsell',
  COMPANY_CLICK_GUIDE: 'Company: Click Sidebar Guide',
  COMPANY_VIEW_LESSON_PAGE: 'Company: View Example Questions',
  COMPANY_CLICK_LESSON: 'Company: Click Lesson',
  COMPANY_CLICK_SIDEBAR: 'Company: Click Sidebar',

  // Guides Page
  VIEW_GUIDES_PAGE: 'Guides Page: View Guides Page',
  GUIDES_VIEW_SIGNUP_WALL: 'Guides: View Signup Wall',
  GUIDES_CLICK_SIGNUP_WALL: 'Guides: Click Signup Wall',

  // Guide Page
  GUIDE_CLICK_CARD: 'Guide Page: Click Card',
  GUIDE_CLICK_SIGNUP_CTA: 'Guide Page: Click Signup CTA',

  // Question List Page
  QUESTION_LIST_VIEW: 'View Post List',
  QUESTION_LIST_CLICK_VIDEO: 'Post List: Click Video ID',
  QUESTION_LIST_CLICK_QUESTION: 'Post List: Click Post',
  QUESTION_LIST_VIEW_SIGNUP_WALL: 'Post List: View Signup Wall',
  QUESTION_LIST_CLICK_SAVE: 'Post List: Click Save',
  QUESTION_LIST_CLICK_SEEN_QUESTION: 'Post List: Click Seen Question',
  QUESTION_LIST_CLICK_VIEW_ANSWERS: 'Post List: Click View Answers',
  QUESTION_LIST_CLICK_ADD_ANSWER: 'Post List: Click View Answers',
  QUESTION_LIST_EXPAND_ANSWER_PREVIEW: 'Post List: Expand Answer Preview',
  QUESTION_LIST_SEARCH: 'Post List: Search',
  QUESTION_LIST_CLICK_CONTRIBUTE: 'Post List: Click Contribute',
  QUESTION_LIST_VIEW_CONTRIBUTE_MODAL: 'Post List: View Contribute Modal',
  QUESTION_LIST_COMPANY_FILTER_UPSELL: 'Post List: Company Filter Upsell',
  QUESTION_LIST_DIFFICULTY_FILTER_UPSELL: 'Post List: Difficulty Filter Upsell',
  QUESTION_LIST_VIEW_SLIDE_IN_CONTRIBUTE_CTA: 'Post List: View Slide In Contribute CTA',
  QUESTION_LIST_FOLLOW_TAG: 'Post List: Follow Tag',
  QUESTION_LIST_UNFOLLOW_TAG: 'Post List: Unfollow Tag',
  QUESTION_LIST_SORT_BY: 'Post List: Sort By',
  QUESTION_LIST_CLICK_RELATED_ITEM: 'Post List: Click Related Items',
  QUESTION_LIST_CLICK_TRENDING_COMPANY: 'Post List: Click Trending Company',
  QUESTION_LIST_CLICK_COLLECTION: 'Post List: Click Collection',

  // Question Page
  QUESTION_VIEW: 'View Post',
  QUESTION_CLICK_READ_MORE: 'Post: Click Read More',
  QUESTION_VIEW_SIGNUP_WALL: 'Post: View Signup Wall',
  QUESTION_CLICK_SIGNUP_WALL: 'Post: Click Signup',
  QUESTION_VIEW_UPGRADE_WALL: 'Post: View Upgrade Wall',
  QUESTION_CLICK_UPGRADE_WALL: 'Post: Click Upgrade Wall',
  QUESTION_COMMENT_SIGNUP: 'Post: Click Comment Signup',
  QUESTION_START_COMMENT: 'Post: Start Comment',
  QUESTION_SUBMIT_COMMENT: 'Post: Submit Comment',
  QUESTION_UPVOTE: 'Post: Upvote',
  QUESTION_SHARE: 'Post: Share',
  QUESTION_UPVOTE_COMMENT: 'Post: Upvote Comment',
  QUESTION_VIEW_REPLIES: 'Post: View Replies',
  QUESTION_CLICK_COURSES: 'Post: Click See Courses',
  QUESTION_CLICK_RELATED_COURSE: 'Post: Click Related Course',
  QUESTION_CLICK_RELATED_LESSON: 'Post: Click Related Lesson',
  QUESTION_VIEW_VIDEO_UPSELL: 'Post: View Video Upsell',
  QUESTION_CLICK_VIDEO_UPSELL: 'Post: Click Video Upsell',
  QUESTION_CLICK_VIDEO_UPGRADE: 'Post: Click Video Upgrade',
  QUESTION_VIEW_VIDEO: 'Post: View Video',
  QUESTION_PLAY_VIDEO: 'Post: Play Video',
  QUESTION_CLICK_AFFILIATE: 'Post: Click Affiliate',
  QUESTION_CLICK_TAG: 'Post: Click Tag',
  QUESTION_CLICK_SEEN_QUESTION: 'Post: Click Seen Question',
  QUESTION_CLICK_FLAG: 'Post: Click Flag',
  QUESTION_CLICK_INTERVIEW_COMPANY: 'Post: Click Interview Company',
  QUESTION_CLICK_INTERVIEW_ANSWER: 'Post: Click Interview Answer',
  QUESTION_CLICK_RELATED: 'Post: Click Related',
  QUESTION_VIEW_EXPERT_ANSWER: 'Post: View Expert Answer',
  QUESTION_CLICK_EXPERT_ANSWER_UPGRADE: 'Post: Click Expert Answer Upgrade',
  QUESTION_COMMENT_SORT: 'Post: Sort Comments',

  // Add Interview Page
  CONTRIBUTE_VIEW: 'View Contribute',
  CONTRIBUTE_CLICK_GUIDELINES: 'Contribute: Click Guidelines',
  CONTRIBUTE_CLICK_SUBMIT: 'Contribute: Click Submit',
  CONTRIBUTE_SUCCESSFUL_SUBMIT: 'Contribute: Successful Submit',
  CONTRIBUTE_VIEW_SCHEDULE_PAGE: 'Contribute: View Schedule Page',
  CONTRIBUTE_CLICK_SCHEDULE: 'Contribute: Click Schedule',

  // User Profile Events
  PROFILE_SELF_VIEW: 'Profile: View Self',
  PROFILE_USER_VIEW: 'Profile: View User',
  PROFILE_VIEW_SAVED_CONTENT: 'Profile: View Saved Content',
  PROFILE_CLICK_SAVED_CONTENT: 'Profile: Click Saved Content',
  PROFILE_SUBMIT_EDIT: 'Profile: Submit Edit',
  PROFILE_FOLLOW_USER: 'Profile: Follow User',
  PROFILE_UNFOLLOW_USER: 'Profile: Unfollow User',
  QUESTION_CLICK_MEMBER_BADGE: 'Post: Click Member Badge',
  QUESTION_CLICK_COACH_BADGE: 'Post: Click Coach Badge',

  // Account Page
  ACCOUNT_VIEW: 'View Account',
  ACCOUNT_SET_NAME: 'Account: Set Name',
  ACCOUNT_SET_EMAIL: 'Account: Set Email',
  ACCOUNT_SET_PASSWORD: 'Account: Set Password',

  // Referral Page
  VIEW_REFERRALS: 'View Referrals',
  VIEW_REFERRALS_LANDING: 'View Referrals Landing',
  REFERRALS_CLICK_COPY_TEXT: 'Referrals: Click Copy Text',
  REFERRALS_SEND_EMAIL: 'Referrals: Send Email',
  REFERRALS_CLICK_REWARD_SUBS: 'Referrals: Click Reward Subscription',
  REFERRALS_CLICK_REWARD_COACHING: 'Referrals: Click Reward Coaching',
  REFERRALS_CLICK_VIEW: 'Account: View Referrals',

  // Gift Page
  VIEW_GIFT_PAGE: 'View Gift Page',
  VIEW_GIFT_PURCHASED_PAGE: 'View Gift Purchased Page',

  // Redeem Gift Page
  VIEW_REDEEM_GIFT_PAGE: 'View Redeem Gift Page',

  // Invite Code Page
  VIEW_INVITE_CODE_PAGE: 'View Invite Code Page',

  // Cancel Account Modal
  CANCEL_MODAL_VIEW: 'Cancel Popup: View',
  CANCEL_MODAL_CLICK: 'Cancel Popup: Click',
  CANCEL_MODAL_CLOSE: 'Cancel Popup: Close',

  // Onboarding Page events
  VIEW_ONBOARDING: 'View onboarding',
  SUBMIT_ONBOARDING: 'Onboarding: Submit Step',
  SKIPPED_ONBOARDING: 'Onboarding: Skipped',
  FINISHED_ONBOARDING: 'Onboarding: Finished',

  // User Dashboard events
  VIEW_DASHBOARD: 'View Dashboard',
  DASHBOARD_CLICK_LESSON: 'Dashboard: Click Lesson',
  DASHBOARD_CLICK_MY_COURSE: 'Dashboard: Click My Course',
  DASHBOARD_COLLAPSE_MY_COURSES: 'Dashboard: Collapse My Courses',
  DASHBOARD_CLICK_MORE_COURSES: 'Dashboard: Click More Courses',
  DASHBOARD_CLICK_QUESTION: 'Dashboard: Click Question',
  DASHBOARD_CLICK_MORE_QUESTIONS: 'Dashboard: Click More Questions',
  DASHBOARD_CLICK_COACHING: 'Dashboard: Click Coaching',
  DASHBOARD_CLICK_MORE_COACHING: 'Dashboard: Click More Coaching',
  DASHBOARD_CLICK_ACTIVITY: 'Dashboard: Click Activity',
  DASHBOARD_CLICK_GUIDE: 'Dashboard: Click Guide',
  DASHBOARD_SEE_ALL_GUIDES: 'Dashboard: See All Guides',
  DASHBOARD_CLICK_DUET: 'Dashboard: Click Duet',
  DASHBOARD_CLICK_DISCUSSION: 'Dashboard: Click Discussion',
  DASHBOARD_VIEW_ONBOARDING_VIDEO: 'Dashboard: View Onboarding Video',
  DASHBOARD_PLAY_ONBOARDING_VIDEO: 'Dashboard: Play Onboarding Video',
  DASHBOARD_CLOSE_ONBOARDING_VIDEO: 'Dashboard: Close Onboarding Video',
  DASHBOARD_CLICK_COPY_REFERRAL_CODE: 'Dashboard: Click Copy Referral Code',

  // Daily Challenge Card events
  CHALLENGE_CLICK_WATCH_VIDEO: 'Challenge: Click Watch Video',
  CHALLENGE_CLICK_SHUFFLE_VIDEO: 'Challenge: Click Shuffle Video',
  CHALLENGE_CLICK_ANSWER_QUESTION: 'Challenge: Click Answer Question',
  CHALLENGE_CLICK_SHUFFLE_QUESTION: 'Challenge: Click Shuffle Question',

  // Coaching events
  COACH_LANDING_VIEW: 'Coaching: View Landing',
  COACH_CLICK_PROFILE: 'Coaching: Click Profile',
  COACH_MODAL_VIEW: 'Coaching Modal: View',
  COACH_MODAL_UPDATE: 'Coaching Modal: Update',
  COACH_MODAL_CLOSE: 'Coaching Modal: Close',
  COACH_PROFILE_VIEW: 'Coaching Profile: View',
  COACH_CLICK_BOOK: 'Coaching: Click Book',
  COACH_CLICK_AVAILABILITY: 'Coaching: Click Availability',
  COACH_VIEW_AVAILABILITY: 'Coaching: View Availability',
  COACH_SELECT_AVAILABILITY: 'Coaching: Select Availability',
  COACH_RUSH_CLICK_CTA: 'Coaching: Click Rush Order CTA',
  COACH_RUSH_CLICK_ROLE: 'Coaching: Click Rush Order Role',
  COACH_RUSH_CLICK_TIME: 'Coaching: Select Rush Booking Time',
  COACH_RUSH_PAYMENT_SUCCESS: 'Coaching: Rush Order Payment Success',
  COACH_PREMIUM_CLICK_CTA: 'Coaching: Click Premium Bundle CTA',
  COACH_PREMIUM_CLICK_MODAL_CTA: 'Coaching: Click Premium Bundle Modal CTA',
  COACH_PREMIUM_CLICK_ROLE: 'Coaching: Click Premium Bundle Role',
  COACH_PREMIUM_CLICK_TIME: 'Coaching: Click Premium Bundle Booking Time',
  COACH_PREMIUM_PAYMENT_SUCCESS: 'Coaching: Premium Bundle Payment Success',
  COACH_CONSULTATION_CLICK_CTA: 'Coaching: Click Consultation CTA',
  COACH_CONSULTATION_CLICK_ROLE: 'Coaching: Click Consultation Role',
  COACH_CONSULTATION_CLICK_TIME: 'Coaching: Click Consultation Time',
  COACH_CONSULTATION_PAYMENT_SUCCESS: 'Coaching: Consultation Payment Success',
  COACH_CLICK_PACKAGE: 'Coaching: Click Package',
  COACH_CLICK_NEGOTIATION_PACKAGE: 'Coaching: Click Negotiation Package',
  COACH_CLICK_BOOKING_LINK: 'Coaching Profile: Click Booking Link',
  COACH_PROFILE_CLICK_BOOK_WITH_CREDITS: 'Coaching Profile: Click Book with Credits',
  COACH_PROFILE_CLICK_RESUME_UPLOAD: 'Coaching Profile: Click Resume Upload',

  // Coach Booking Page
  COACH_BOOKING_VIEW: 'View Coach Booking',
  COACH_BOOKING_CONFIRM: 'Coach Booking: Confirm',
  COACH_BOOKING_CLICK_SWITCH: 'Coach Booking: Click Switch',

  // Coaching Programs
  COACH_PROGRAM_CLICK: 'Coaching: Click Program',
  COACH_PROGRAM_MODAL_VIEW: 'Coaching: View Program Modal',
  COACH_PROGRAM_MODAL_SUBMIT: 'Coaching: Submit Program Modal',

  // New Coaching Landing Page
  COACHING_LANDING_VIEW: 'Coaching: View New Landing',
  COACHING_LANDING_CLICK_SERVICE: 'Coaching: Click Service',
  COACHING_LANDING_CLICK_CTA: 'Coaching: Click CTA',

  // Plan Modal
  PLAN_MODAL_VIEW: 'Plan Modal: View',
  PLAN_MODAL_CLICK_BUY: 'Plan Modal: Click Buy',
  PLAN_MODAL_CLOSE: 'Plan Modal: Close',
  PLAN_MODAL_SEE_PLANS: 'Plan Modal: See Plans',

  // Duet Events
  DUET_VIEW_LANDING: 'Duet: View Landing',
  DUET_JOIN_WAITING_ROOM: 'Duet: Join Waiting Room',
  DUET_CLICK_TEST_AV: 'Duet: Click Test AV',
  DUET_GET_MATCH: 'Duet: Get Match',
  DUET_JOIN_MATCH: 'Duet: Join Match',
  DUET_NO_MATCH: 'Duet: No Match',
  DUET_MATCH_ERROR: 'Duet: Match Error',
  DUET_NO_MATCH_RESCHEDULE: 'Duet: No Match Reschedule',
  DUET_SEND_MESSAGE: 'Duet: Send Message',
  DUET_CLICK_SOCRATES: 'Duet: Click Socrates',
  DUET_REJOIN_MATCH: 'Duet: Rejoin Match',
  DUET_VIEW_VIDEO_ROOM: 'Duet: View Video Room',
  DUET_COMPLETE_SESSION: 'Duet: Complete Session',
  DUET_FEEDBACK_VIEW_FORM: 'Duet: Feedback View Form',
  DUET_FEEDBACK_COMPLETE: 'Duet: Feedback Complete',
  DUET_FEEDBACK_SKIP: 'Duet: Feedback Skip',
  DUET_FEEDBACK_VIEW_PEER_TAB: 'Duet: Feedback View Peer Tab',
  DUET_FEEDBACK_VIEW_AI_TAB: 'Duet: Feedback View AI Tab',
  DUET_FEEDBACK_VIEW_TRANSCRIPT_TAB: 'Duet: Feedback View Transcript Tab',
  DUET_FEEDBACK_CLICK_SUBMIT_FEEDBACK: 'Duet: Feedback Click Submit Feedback',
  DUET_FEEDBACK_CLICK_COACHING_UPSELL: 'Duet: Feedback Click Coaching Upsell',
  DUET_FEEDBACK_CLICK_AI_UPSELL: 'Duet: Feedback Click AI Upsell',
  DUET_FEEDBACK_RATE_AI_FEEDBACK: 'Duet: Feedback Rate AI Feedback',
  DUET_CLICK_SIGNUP_WALL: 'Duet: Click Signup Wall',
  DUET_CLICK_UPGRADE: 'Duet: Click Upgrade',
  DUET_SUGGESTED_QUESTION: 'Duet: Suggested Question',
  DUET_SCHEDULE_REQUEST: 'Duet: Schedule Request',
  DUET_CANCEL_REQUEST: 'Duet: Cancel Request',
  DUET_CREATE_ON_DEMAND_SESSION: 'Duet: Create On Demand Session',
  DUET_VIEW_TROUBLESHOOTING: 'Duet: View Troubleshooting',
  DUET_VIEW_AV_ERROR: 'Duet: View AV Error',
  DUET_CLICK_GUIDELINES: 'Duet: Click Guidelines',
  DUET_SET_RECORDING_DISABLED: 'Duet: Set Recording Disabled',
  DUET_CREATE_AI_SESSION: 'Duet: Create AI Session',
  DUET_VIEW_AI_SESSION: 'Duet: View AI Session',
  DUET_RESET_QUESTION_AI_SESSION: 'Duet: Reset Question AI Session',
  DUET_COMPLETE_AI_SESSION: 'Duet: Complete AI Session',

  // Exit Intent Modal
  EXIT_MODAL_VIEW: 'Exit Modal: View',
  EXIT_MODAL_SUBMIT: 'Exit Modal: Submit',

  // Job Referrals
  JOB_REFERRAL_LANDING_VIEW: 'Job Referral Landing: View',
  JOB_REFERRAL_REQUEST_VIEW: 'Job Referral Request: View',
  JOB_REFERRAL_REQUEST_WAITLIST_VIEW: 'Job Referral Request Waitlist: View',
  JOB_REFERRAL_REQUEST_SUBMIT: 'Job Referral Request: Submit',
  JOB_REFERRAL_REQUEST_SUCCESSFUL_SUBMIT: 'Job Referral Request: Successful Submit',
  JOB_REFERRAL_REQUEST_LIST_VIEW: 'Job Referral: View Request List',
  JOB_REFERRAL_REQUEST_LIST_CLICK_GET_REFERRAL: 'Job Referral: Request List Click Get Referral',
  JOB_REFERRAL_REQUEST_LIST_CLICK_UPGRADE: 'Job Referral: Request List Click Upgrade',
  JOB_REFERRAL_REQUEST_LIST_CLICK_QUESTIONS: 'Job Referral: Request List Click Questions',
  JOB_REFERRAL_REQUEST_LIST_CLICK_COACHING: 'Job Referral: Request List Click Coaching',
  JOB_REFERRER_SIGNUP_VIEW: 'Job Referrer: View Signup',
  JOB_REFERRER_SIGNUP_SUBMIT: 'Job Referrer: Submit Signup',
  JOB_REFERRER_CANDIDATE_LIST_VIEW: 'Job Referrer: View Candidate List',
  JOB_REFERRER_CLICK_CLAIM: 'Job Referrer: Click Claim',
  JOB_REFERRER_CLICK_UNCLAIM: 'Job Referrer: Click Unclaim',
  JOB_REFERRER_CLICK_EMAIL: 'Job Referrer: Click Email',
  JOB_REFERRER_CLICK_MEMBER_BADGE: 'Job Referrer: Click Member Badge',
  JOB_REFERRER_UPDATE_PROFILE_VIEW: 'Job Referrer: View Update Profile Page',
  JOB_REFERRER_UPDATE_PROFILE: 'Job Referrer: Update Profile',

  // Jobs Page
  JOBS_VIEW: 'View Jobs Page',
  JOBS_CLICK_APPLY: 'Jobs: Click Apply',

  // Masterclasses
  MASTERCLASS_VIEW_LANDING: 'Masterclass: View',
  MASTERCLASS_CLICK_BOOK: 'Masterclass: Click Book',
  MASTERCLASS_VIEW_PURCHASE_SUCCESS: 'Masterclass: View Purchase Success',

  // First 90 Days
  FIRST_90_DAYS_LANDING_VIEW: 'First 90 Days Landing: View',

  // Affiliate
  AFFILIATE_LANDING_VIEW: 'View Affiliate Landing',

  // PRAMP
  PRAMP_ACCOUNT_CREATION_MODAL_VIEW: 'Pramp Account Creation Modal: View',
  PRAMP_ACCOUNT_CREATION_MODAL_SUBMIT: 'Pramp Account Creation Modal: Submit',
  PRAMP_ACCOUNT_CREATION_MODAL_CANCEL: 'Pramp Account Creation Modal: Cancel',
  PRAMP_WELCOME_BACK_MODAL_VIEW: 'Pramp Welcome Back Modal: View',

  // Support Page
  SUPPORT_VIEW: 'View Support',

  // Hub Page
  HUB_VIEW: 'Hub: View',
  HUB_FOLLOW_TAG: 'Hub: Follow Tag',
  HUB_UNFOLLOW_TAG: 'Hub: Unfollow Tag',
  HUB_CLICK_CARD: 'Hub: Click Card',
  HUB_SEE_MORE: 'Hub: See More',
  HUB_CLICK_CONTRIBUTE: 'Hub: Click Contribute',
  HUB_CLICK_GET_REFERRED: 'Hub: Click Get Referred',

  // Directory Page
  DIRECTORY_VIEW: 'Directory: View',
  DIRECTORY_CLICK: 'Directory: Click',

  // Resume Service
  RESUME_VIEW_LANDING: 'Resume: View Landing',
  RESUME_VIEW_FORM: 'Resume: View Form',
  RESUME_CLICK_UPLOAD: 'Resume: Click Upload',
  RESUME_SUBMIT: 'Resume: Submit Resume For Review',

  // Karma
  KARMA_CLICK_SCORE: 'Karma: Click Score',
  KARMA_VIEW_KARMA_PAGE: 'Karma: View Karma Page',
  KARMA_CLICK_ACTIVITY: 'Karma: Click Activity',
  KARMA_LEADERBOARD_CLICK_USER: 'Karma: Leaderboard Click User',

  // Blog
  BLOG_VIEW: 'View Blog',
  BLOG_VIEW_POST: 'Blog: View Post',
  BLOG_CLICK_SIGNUP_CTA: 'Blog: Click Signup CTA',
  BLOG_CLICK_CARD: 'Blog: Click Card',

  // Skill page
  SKILL_LANDING_VIEW: 'Skill: View Landing',
  SKILL_CLICK_COACH: 'Skill: Click coach',
  SKILL_CLICK_MORE_COACH: 'Skill: Click more coaches',

  // Analytics page
  ANALYTICS_VIEW_DASHBOARD: 'Analytics: View dashboard',

  // Reviews page
  REVIEWS_VIEW: 'View Reviews',

  // MDX components
  QUESTION_WIDGET_CLICK_QUESTION: 'Question Widget: Click question',
};

export default EventType;
